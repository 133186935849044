import React from "react";
import { assignTeam } from "../../services/assignTeam";
import useStore from "../../store";
import { useParams } from "react-router-dom";
import Player from "./Player";
import withTracking from "../../utils/withTrackingClick";

const Operatives = ({ operatives, teamId }) => {
  const userId = useStore((state) => state.userId);
  const gameCode = useParams().gameCode;
  const game = useStore((state) => state.game);

  const handleClickJoinOperative = () => {
    assignTeam(userId, gameCode, teamId, "operative");
  };

  return (
    <div className="operatives-container">
      <ul>
        {operatives.map((operative, index) => {
          return (
            <li key={index}>
              <Player player={operative} />
            </li>
          );
        })}
      </ul>

      {operatives.filter((operative) => operative._id === userId).length ===
        0 &&
        !game.locked && (
          <button
            className="join-btn"
            onClick={withTracking(handleClickJoinOperative, "JoinOperative")}
          >
            Join operatives
          </button>
        )}
    </div>
  );
};

export default Operatives;
