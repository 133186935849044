import React from "react";
import { assignTeam } from "../../services/assignTeam";
import useStore from "../../store";
import { useParams } from "react-router-dom";

import Player from "./Player";
import withTracking from "../../utils/withTrackingClick";

const Spymaster = ({ spymaster, teamId }) => {
  const userId = useStore((state) => state.userId);
  const game = useStore((state) => state.game);
  const gameCode = useParams().gameCode;

  const currentTeamIndex = game.currentTeamIndex;
  const currentTeam = game.teams[currentTeamIndex];
  const currentRole = game.currentRole;

  const getIsPulsing = () => {
    if (game?.status !== "In-Progress" || currentRole !== "Spymaster")
      return false;

    return currentTeam?.spymaster?._id === userId;
  };

  const handleClickJoinSpymaster = () => {
    assignTeam(userId, gameCode, teamId, "spymaster");
  };

  return (
    <div className="spymaster-container">
      {spymaster ? (
        <Player player={spymaster} isPulsing={getIsPulsing()} />
      ) : !game.locked ? (
        <button
          className="join-btn"
          onClick={withTracking(handleClickJoinSpymaster, "JoinSpymaster")}
        >
          Become spymaster
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Spymaster;
