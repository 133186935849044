import React from "react";
import "./BreathingBar.css";
import useStore from "../store";

const BreathingBar = () => {
  const game = useStore((state) => state.game);
  const teamWonIndex = [0, 1, 2].find(
    (index) => game.winningTeamIndex === index
  );

  const getBreathingBarColor = () => {
    if (teamWonIndex !== null && teamWonIndex !== undefined) {
      const teamWon = game.teams[teamWonIndex];
      const teamWonColor = teamWon.color.toLowerCase();

      return teamWonColor;
    }

    const currentTeam = game.teams[game.currentTeamIndex];
    const currentTeamColor = currentTeam.color.toLowerCase();

    return currentTeamColor;
  };

  if (teamWonIndex !== null && teamWonIndex !== undefined) {
    return (
      <div
        className={`breathing-bar breathing-bar--flicker breathing-bar--${getBreathingBarColor()} `}
      >
        <span className="breathing-bar__text">
          {game.teams[teamWonIndex]?.color} WON
        </span>
      </div>
    );
  } else {
    return (
      <div
        className={`breathing-bar breathing-bar--breathing breathing-bar--${getBreathingBarColor()} breathing-bar--${
          game.status === "In-Progress" ? "active" : ""
        }`}
      >
        {game.status !== "In-Progress" && game.status !== "Initialized" && (
          <span className="breathing-bar--idle">
            {game.status === "Ended" ? "Ended" : `Paused - ${
              game.teams[game.currentTeamIndex].color.toLowerCase()
            } ${game.currentRole}'S turn`}
          </span>
        )}
        {
          game.status === "In-Progress" && (
            <span className="breathing-bar--idle">
              Playing - {game.teams[game.currentTeamIndex].color.toLowerCase()} {game.currentRole}'S turn
            </span>
          )
        }
        {
          game.status === "Initialized" && (
            <span className="breathing-bar--idle">
              {game.teams[game.currentTeamIndex].color.toLowerCase()}'S {game.currentRole} will start. Press play button when ready.
            </span>
          )
        }
      </div>
    );
  }
};

export default BreathingBar;
