import React, { useEffect, useState } from "react";
import useStore from "../store";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { FaEdit, FaInstagram } from "react-icons/fa";

import "./Home.css";
import LoadingBar from "../components/LoadingBar";
import { Helmet } from "react-helmet";
import structuredDataVideoGame from "../constants/structuredDataVideoGame";

const Home = () => {
  const userId = useStore((state) => state.userId);
  const setPlayer = useStore((state) => state.setPlayer);

  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const createGame = () => {
    if (isCreating) return;
    setIsCreating(true);

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games`, { userId })
      .then((response) => {
        const game = response.data;
        navigate(`/game/${game.code}`);
      })
      .catch((error) => {
        console.error("Error creating game:", error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const handleClickEditName = () => {
    const newName = prompt("Enter new name");

    if (!newName) return;
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/changeName`, {
        userId,
        newName,
      })
      .then((response) => {
        const data = response.data;
        if (data.newName) {
          localStorage.setItem("Cryptik_playerName", data.newName);
          setPlayer(data.newName, localStorage.getItem("Cryptik_playerToken"));
        }
      });
  };

  useEffect(() => {
    const justLoggedIn = location.state?.justLoggedIn;
    if (justLoggedIn) {
      createGame();
    }
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.cryptik.me${window.location.pathname}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredDataVideoGame)}
        </script>
      </Helmet>
      <div className="home">
        <LoadingBar isGreen={true} />
        <h1>Play Cryptik</h1>
        <h2>An online multiplayer strategy game</h2>
        <h3 className="home-welcome">
          <button
            className="icon-btn change-name-btn"
            onClick={() => handleClickEditName()}
          >
            <FaEdit />
          </button>
          <strong>{useStore((state) => state.playerName)}</strong>
        </h3>

        <div>
          <button
            className={`create-room-btn`}
            onClick={() => createGame()}
            disabled={isCreating}
          >
            {isCreating ? <div className="spinner"></div> : "CREATE NEW ROOM"}
          </button>
          {/* <button
            className={`create-room-btn`}
            disabled={true}
          >
            BROWSE PUBLIC ROOMS
          </button> */}
        </div>

        <div>
          <div className="highlight-box">
            <h4>How to play</h4>

            <ol className="manual">
              <li>
                <strong>Spymaster:</strong> Lead your team to victory! Give
                clues to identify your team's words.
              </li>
              <li>
                <strong>Operative:</strong> Crack the clues! Pick words you
                think match your Spymaster's hints.
              </li>
              <li>
                <strong>A clue:</strong> One word and a number. Example: "Ocean
                3" hints at 3 'ocean'-related words on the board.
              </li>
              <li>
                <strong>Winning:</strong> Uncover all your team's words first to
                win.
              </li>
              <li>
                <strong>Beware the Assassin:</strong> Avoid the assassin (black)
                word, or your team loses instantly!
              </li>
              <li>
                <strong>Neutral Words: </strong>
                Guessing a neutral (gray) word ends your turn, so guess wisely.
              </li>
              <li>
                <strong>Cryptik</strong> is best enjoyed with <strong>voice chat</strong>. Use Discord or a similar platform to communicate with your team. 
              </li>
            </ol>

            <em>Good luck, and have fun!</em>
          </div>
        </div>

        <div className="mt--6 text-center mb--2">
          <p className="mb--4 font--light">For more updates</p>
          <a
            title="Follow Cryptik game on Instagram"
            href="https://instagram.com/playcryptik"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <FaInstagram size={25} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
